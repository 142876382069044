.slick-list .slick-track {
    display: flex !important;
    gap: 8px !important;
    margin: unset !important;
}

.slick-track::before,
.slick-track::after {
    content: '';
    display: none !important;
}

.slick-prev {
    left: 3% !important;
    z-index: 1;
}
.slick-next {
    right: 3% !important;
    z-index: 1;
}
