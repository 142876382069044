.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
}

.controlsWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    gap: 1rem;
}

.statsWrapper {
    width: 300px;
}

.searchWrapper {
    width: 240px;
}

.totalText {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    color: #000;
    margin-right: 4px;
}

.activeText {
    font-size: 16px;
    line-height: 22px;
    color: var(--ag-color-gray1);
    text-align: center;
}

.buttonContent {
    display: flex;
    align-items: center;
    gap: 1rem;
}
