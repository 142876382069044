.transparent {
    opacity: 0.5;
}

.opaque {
    opacity: 1;
}

.status {
    display: flex;
}

.text {
    font-size: 20px;
}

.subtext {
    font-size: 15px;
}
