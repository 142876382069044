.modalContentTitle {
    font-size: 20px;
    margin-bottom: 12px !important;
}

.content p {
    font-weight: bold;
}

.content p span {
    font-weight: 500;
}

.content li {
    list-style-type: none;
}

.date {
    position: relative;
    top: -20px;
    left: -12px;
}

.modal {
    min-width: 400px;
    padding: 0 12px 20px 12px;
}
