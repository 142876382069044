.spotTitle,
.dropdown {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 0.25rem;
}

.subHeaderLabel {
    line-height: 3rem;
    margin-left: 0.5rem;
}

.subHeader {
    display: flex;
    align-items: center;
}

.subHeaderWrapper {
    display: flex;
    flex-wrap: wrap;
}

@media (min-width: 992px) {
    .subHeaderWrapper {
        justify-content: flex-end;
    }
    .smallerScreenSubheader {
        display: none;
    }
    .subHeader:last-of-type {
        margin-left: 40px;
    }
}

@media (max-width: 991px) {
    .biggerScreenSubheader {
        display: none !important;
    }
    .subHeader:first-of-type {
        margin-right: 40px;
    }
}
