.galleryContainer {
    display: grid;
    place-items: center;
    height: 200px;
    position: relative;
}

.galleryImage {
    position: absolute;
    transition: transform 0.3s ease-in-out;
    max-width: 110px;
    height: 150px;
    border-radius: 10px;
    border: 1px solid white;
    background: #f5f5f5;
    object-fit: cover;
    box-shadow: 0px 0px 7.77px 0px #00000040;
}
