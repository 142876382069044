.agTable {
    border-spacing: 0;
    font-size: 14px;
    font-weight: 700;
    text-align: end;
    table-layout: fixed;
    width: 100%;
}

.agTable td {
    padding: 12px;
    height: 48px;
    border-top: 0.5px solid var(--ag-color-gray7);
    border-left: 0.5px solid var(--ag-color-gray7);
    width: auto;
}

.agTable tr td:first-child {
    text-align: start;
}

.agTable tr td:last-child {
    border-right: 0.5px solid var(--ag-color-gray7);
}

.agTable tr:last-of-type td {
    border-bottom: 0.5px solid var(--ag-color-gray7);
}

.agTable tr:first-of-type td:first-child {
    border-top-left-radius: 6px;
}

.agTable tr:first-of-type td:last-child {
    border-top-right-radius: 6px;
}

.agTable tr:last-of-type td:first-child {
    border-bottom-left-radius: 6px;
}

.agTable tr:last-of-type td:last-child {
    border-bottom-right-radius: 6px;
}

.agTable td svg {
    vertical-align: middle;
    margin-left: 8px;
    height: 16px;
    width: 16px;
}

.agTable .caret {
    background-color: #000;
    border-radius: 8px;
    padding: 4px;
    transition-duration: 200ms;
    transform: rotate(-90deg);
}

.agTable .caret path {
    fill: #fff;
}

.agTable .caret.expanded {
    transform: rotate(90deg);
}

.agTable .indentedRow td:first-of-type {
    padding-left: 24px;
}

.agTable .indentedRow td {
    font-weight: 500;
}

.agTable .clickableRow td {
    cursor: pointer;
}

.agTable .clickableRow:hover {
    background-color: var(--ag-color-gray5);
}
