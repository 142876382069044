.data {
    font-size: 1.75rem;
    line-height: 3rem;
    margin-top: 0.25rem;
    display: block;
}

@media (min-width: 1200px) {
    .data {
        font-size: 2.2rem;
    }
}
