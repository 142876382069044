/*******************************
         Site Overrides
*******************************/
.ui.form .fields .field .ui.input input, .ui.form .field .ui.input input {
    background: #F2F2F2;
    color: #333;
    border: 0px solid #fff;
    -webkit-tap-highlight-color: #EDEDED;
    width: 100%;
    height: 49px;
}

.ui.form .required.fields:not(.grouped) > .field > label:after, .ui.form .required.fields.grouped > label:after, .ui.form .required.field > label:after, .ui.form .required.fields:not(.grouped) > .field > .checkbox:after, .ui.form .required.field > .checkbox:after {
    margin: -0.2em 0em 0em 0.2em;
    content: '*';
    color: #ff702d !important;
}