.wrapper {
    width: 100%;
    border-radius: 6px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    font-family: 'Outfit';
}
.md {
    font-size: 20px;
    padding: 16px;
}
.sm {
    font-size: 14px;
    padding: 6px;
}
.primary {
    background-color: var(--ag-primary);
    color: var(--ag-background);
}
.primary svg path {
    fill: var(--ag-background);
}
.primary:hover {
    background-color: #d1581f;
}
.secondary {
    background-color: var(--ag-color-gray5);
    color: var(--ag-primary);
    border: 1px solid var(--ag-primary);
}
.secondary svg path {
    fill: var(--ag-primary);
}
.secondary:hover {
    background-color: var(--ag-color-gray6);
}
.error-outline {
    background-color: white;
    color: var(--ag-error);
    border: 1px solid var(--ag-error);
}
.error-outline svg path {
    fill: var(--ag-error);
}
.error-outline:hover {
    background-color: var(--ag-color-gray5);
}
.info {
    background-color: #ffffff;
    color: #707070;
    border: 1px solid var(--ag-color-gray7);
}
.info svg path {
    fill: #707070;
}
.info:hover {
    background-color: var(--ag-color-gray5);
}
.wrapper:disabled {
    cursor: not-allowed;
}
.primary:disabled {
    background-color: var(--ag-color-disabled);
}
.secondary:disabled {
    background-color: var(--ag-color-disabled);
    border: 1px solid var(--ag-color-disabled);
}
.info:disabled {
    background-color: var(--ag-color-disabled);
    color: var(--ag-background);
}
.info:disabled svg path {
    fill: var(--ag-background);
}
.buttonContent {
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 2rem;
}

.linkLikeButton {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 16px;
    color: var(--ag-color-gray1);
    text-decoration: underline;
    text-align: left;
    width: 100%;
    height: 100%;
    max-height: 2rem;
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: 'Outfit';
}

.iconButton {
    border: none;
    background: inherit;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
}

.iconButton:hover svg path {
    opacity: 0.5;
}
