.warningBox {
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 1rem;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.info {
    background-color: var(--ag-background);
    border: 1px solid #000000;
}

.warning {
    background-color: #fff3ee;
    border: 1px solid var(--ag-primary);
}

.error {
    background-color: #e819191a;
    border: 1px solid #dc2626;
}

.warningBoxTitle {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0.8rem;
}

.warningBoxMessage {
    font-weight: 400;
    font-size: 16px;
}

.warningBoxIcon {
    height: 20px;
    width: 20px;
    margin-right: 0.5rem;
}
