.tbl {
    margin: auto;
    border-spacing: 0;
    width: 100%;
    background-color: white;
}

.slctd td {
    border-bottom: 1px solid #fa824c;
    border-top: 1px solid #fa824c !important;
}

.slctd td:first-child {
    border-left: 1px solid #fa824c;
}

.slctd td:last-child {
    border-right: 1px solid #fa824c !important;
}

.tbl th,
.tbl td {
    padding: 0.5rem 1rem;
    height: 2.75rem;
}

.tbl th {
    border-top: 1px solid #d3d6db;
    border-left: 1px solid #d3d6db;
    background-color: #f9f9fc;
    color: #717680;
    font-weight: 500;
    text-align: start;
}

.tbl th:first-of-type {
    border-start-start-radius: 0.75rem;
}

.tbl th:last-of-type {
    border-right: 1px solid #d3d6db;
    border-start-end-radius: 0.75rem;
}

.tbl td {
    border-top: 1px solid #e4e6ee;
    border-left: 1px solid #e4e6ee;
    cursor: pointer;
}

.tbl td:has(.state-tag) {
    padding-top: 0;
    padding-bottom: 0;
}

.tbl tr td:last-child {
    border-right: 1px solid #e4e6ee;
}

.tbl tr:last-child td {
    border-bottom: 1px solid #e4e6ee;
}

.tbl tr:last-child td:first-of-type {
    border-end-start-radius: 0.75rem;
}

.tbl tr:last-child td:last-of-type {
    border-end-end-radius: 0.75rem;
}
.stateTag {
    height: 2rem;
    border-radius: 0.75rem;
    border: none;
    width: 7rem;
    display: grid;
    gap: 6px;
    grid-template-columns: 1fr 2fr;
    align-items: center;
}
.stateActive {
    background-color: #d7f0d8;
    color: #059669;
}
.stateEnded {
    background-color: #fbfbfb;
    color: #717680;
}
.statePending {
    background-color: #fff8d2;
    color: #b69905;
}
.icon,
.text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1rem;
    text-transform: capitalize;
}

.text {
    justify-content: flex-start;
}

.edt {
    border: none;
    background: inherit;
    cursor: pointer;
}
