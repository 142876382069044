.wrapper {
    border-radius: 10px;
    margin: 1rem auto;
    padding: 1rem;
}

.titleWrapper {
    display: grid;
    grid-template-columns: 24px auto;
    align-items: center;
    margin-bottom: 12px;
}

.title {
    font-weight: bold;
    text-align: start;
    font-size: 16px;
}

.wrapper.error {
    border: 1px solid #ee1e34;
    background: #ffe5e8;
    color: #ee1e34;
}

.error svg path {
    fill: #ee1e34;
}

.wrapper.success {
    border: 1px solid #438a60;
    background: #d8f7d9;
    color: #438a60;
}

.success svg path {
    fill: #438a60;
}

.wrapper.warning {
    border: 1px solid var(--ag-primary);
    background: #ffefe4;
    color: var(--ag-primary);
}

.warning svg path {
    fill: var(--ag-primary);
}

.wrapper.info {
    border: 1px solid var(--ag-color-gray7);
    background: #fafafa;
    color: #3e3d43;
}
