.cameras {
    width: 100%;
    display: flex;
    margin: auto;
    justify-content: center;
    gap: 20px;
}

.lprImages {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.lprImagesOnly {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.overheadImages,
.lprImages {
    height: 280px;
}
.lprImagesOnly .lpr {
    height: 180px;
}
.lprImages .lpr {
    height: 130px;
}

@media (min-width: 1200px) {
    .overheadImages,
    .lprImages {
        height: 380px;
    }
    .lprImagesOnly .lpr {
        height: 280px;
    }
    .lprImages .lpr {
        height: 180px;
    }
}

@media (min-width: 1600px) {
    .overheadImages,
    .lprImages {
        height: 480px;
    }
    .lprImages .lpr {
        height: 230px;
    }
    .lprImagesOnly .lpr {
        height: 380px;
    }
}

@media (min-width: 1900px) {
    .overheadImages,
    .lprImages {
        height: 580px;
    }
    .lprImages .lpr {
        height: 280px;
    }
    .lprImagesOnly .lpr {
        height: 480px;
    }
}
