.header,
.filters {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.header {
    margin-bottom: 20px;
    justify-content: space-between;
}
.status {
    text-transform: capitalize;
}
.search {
    width: 200px;
}
.search input {
    width: 150px;
    text-transform: uppercase;
}
.search input::placeholder {
    text-transform: none;
}
.plateImage {
    border-radius: 8px;
    width: 52px;
    height: 40px;
}
.brokenImage {
    display: none;
}

.driverLogContainer table {
    border: none !important;
}

.driverLogContainer table thead {
    background-color: transparent !important;
}

.hasFullImg {
    cursor: pointer !important;
}

.fullImg {
    max-width: 75vh;
}

.imgButton {
    all: unset;
}
