.helpText {
    color: var(--ag-color-gray2);
    font-size: 14px;
}

.inputHelpTextGroupe {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
