.countryAndPhoneWrapper {
    display: flex;
    gap: 4px;
    height: 100%;
    align-items: center;
}
.inputWrapper {
    position: relative;
}
.countryButton {
    background-color: rgba(var(--ag-secondary), var(--ag-secondary-alpha));
    border: none;
    border-right: 1px solid rgba(var(--ag-deactivated), var(--ag-deactivated-alpha));
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    min-width: 3rem;
    height: 100%;
    position: relative;
    color: black;
    font-size: 14px;
}
.plus::before {
    content: '\FF0B';
    display: flex;
    justify-items: center;
    color: rgba(var(--ag-deactivated), var(--ag-deactivated-alpha));
}
.caret {
    transform: rotate(90deg);
}

.combinedInput {
    width: 100%;
    border: none !important;
}
.arrowsIcon {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}
.countryButton span {
    margin-right: 12px;
}

.arrowsButton {
    height: 100%;
    width: 48px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.error {
    border: 2px solid var(--ag-error) !important;
}
