.emailSentText {
    text-align: center;
    font-size: 28px;
}

.emailSentContainer {
    margin: auto;
    margin-bottom: 32px;
}
.loader {
    margin: 40px;
}

.actionItems {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: end;
    margin-inline: 4px;
}

.options,
.filters {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.options {
    margin-block: 40px;
    justify-content: space-between;
    min-height: 40px;
}
.primaryButton {
    width: 120px;
}
