.searchInputContainer {
    overflow: hidden;
    display: flex;
    border: 1px solid var(--ag-deactivated-alpha);
    border-radius: 10px;
    height: 100%;
    background: #fff;
    transition: all 0.4s ease-in-out;
    transform: scaleX(0);
    transform-origin: right;
    opacity: 0;
}

.showInput {
    transform: scaleX(1);
    opacity: 1;
}

.searchBoxContainer {
    position: relative;
    height: 100%;
}

input {
    font-size: 16px;
    outline: none;
    border: none;
    border-radius: 10px;
    color: var(--ag-color-gray1);
    padding-inline: 10px;
}

.searchBoxIcon {
    position: absolute;
    top: 1px;
    right: 5px;
    height: 100%;
    display: flex;
    align-items: center;
}

.clearText {
    all: unset;
    cursor: pointer;
    color: var(--ag-color-gray2);
    font-size: 14px;
    padding: 0 16px;
    height: 100%;
}

.iconButton {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: end;
    height: 100%;
}
