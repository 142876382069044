.agTableWrapper {
    width: 100%;
    border: 0.5px solid var(--ag-color-gray7);
    border-radius: 6px;
    text-align: left;
    padding: 0 !important;
    border-spacing: 0;
}

.agTableWrapper thead {
    font-size: 14px;
    font-weight: 400;
    height: 48px;
    background-color: #f7f8f9;
    text-indent: 10px;
}

.agTableWrapper th:first-child {
    border-top-left-radius: 6px;
}

.agTableWrapper th:last-child {
    padding-right: 10px;
    border-top-right-radius: 6px;
}

.agTableWrapper td {
    height: 54px;
    width: auto;
    border-top: 0.5px solid var(--ag-color-gray7);
    text-indent: 10px;
}
