.wrapper {
    border: 1px solid #e6e8eb;
    border-radius: 1rem;
    margin: 1.5rem 0;
    padding: 2rem;
    position: relative;
}

.headerTitle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 1.5rem;
    align-items: center;
}

.title {
    font-size: 1.5rem;
    font-weight: 700;
}

.updateLabelBox {
    background-color: #f6eae5;
    height: fit-content;
    padding: 0.25rem 0.5rem;
    border-radius: 16px;
    display: flex;
    align-items: center;
}

.updateLabelBox span {
    font-size: 12px;
    font-weight: 300;
    margin-left: 4px;
}

.updateLiveCircle {
    width: 8px;
    height: 8px;
    background-color: #fa824c;
    border-radius: 4px;
}

.emptyWidgetMessage {
    color: #717680;
    display: flex;
    justify-content: center;
    padding: 16% 8%;
}

.typeSelect select {
    min-width: 100px;
    border-radius: 10px;
    border: none;
    background-color: #f3f4f6;
    padding: 8px;
    font-family: Outfit, Helvetica Neue, Arial, Helvetica, sans-serif;
    border-right: 8px solid transparent;
}

.lastUpdatedLabel {
    text-align: right;
}
