.paginationWrapper {
    margin: 1rem;
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    justify-content: end;
}

.paginationWrapper button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
}

.hidden {
    display: none;
}

.selected {
    color: #000000;
    text-decoration: underline;
    font-size: 18px;
    font-weight: 800;
}

.pageButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: end;
    height: 100%;
}

.caretButton {
    border: 0.5px solid var(--ag-color-gray4) !important;
    background-color: white !important;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pageButton span {
    font-size: 18px;
    font-weight: 500;
}
