.highlight {
    font-size: 20px;
    font-weight: 500;
    color: #fa824c;
    background: #ffeecc;
    border-radius: 20px;
    text-align: center;
    padding: 2px;
}

.gradientText {
    background-color: #f3ec78 !important;
    background-image: linear-gradient(45deg, #fa824c, #b919ee) !important;
    background-size: 100% !important;
    -webkit-background-clip: text !important;
    -moz-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    -moz-text-fill-color: transparent !important;
    opacity: 1 !important;
}

.card {
    padding: 0px !important;
    margin: 20px !important;
    width: 400px !important;
}

.cardImage {
    object-fit: cover;
    width: 100%;
    height: 231px !important;
}

.plateImage {
    object-fit: cover;
    width: 100% !important;
    height: 200px !important;
}

.tabHeading {
    letter-spacing: 2px;
    color: #9598a4;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
}

.pane {
    background: #f9f9fc !important;
    box-shadow: 0px 12px 20px rgba(50, 0, 79, 0.05) !important;
}

.learnMoreLink {
    font-weight: bold;
    text-decoration: underline;
    color: #2c3137;
}

#helpText {
    padding-top: 20px;
    color: #2c3137;
    font-size: 16px;
}
