.wrapper {
    width: 720px;
}

.row {
    margin: 1rem 0;
    display: flex;
    gap: 4rem;
    flex-direction: row;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}
