.dateLabels {
    display: grid;
    grid-template-columns: 40px auto;
}

.dateLabels .icon {
    width: 32px;
    height: 32px;
    grid-row: 1/3;
    fill: black;
    border: 1px solid var(--ag-color-gray7);
    padding: 6px;
    border-radius: 16px;
}

.dateLabels .icon svg {
    fill: black;
}

.dateLabels p:last-of-type {
    grid-column: 2;
}

.dateLabels p {
    background-color: #e8e8e8;
    border-radius: 4px;
    padding: 4px 8px;
    width: fit-content;
    font-size: 12px;
    color: var(--ag-color-gray1);
    font-weight: bold;
}

.dateLabels span {
    font-weight: 500;
}
