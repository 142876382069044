.dropdownWrapper {
    margin-top: 8px;
    display: none;
    padding-right: 1px;
    position: absolute;
    background-color: var(--ag-background);
    z-index: 2;
    width: 100%;
    font-size: 16px;
    border: 1px solid var(--ag-deactivated-alpha);
    border-radius: 10px;
    outline: none;
}

.dropdownOpen {
    display: block;
}
.dropdownWrapper .resultsList {
    max-height: 250px;
    overflow-y: auto;
    padding: 0;
    margin-block: 0;
}
.resultsList::-webkit-scrollbar {
    /* width: 7px; */
}
.resultsList::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
}
.resultsList::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 25px;
}

.resultItem {
    cursor: pointer;
    font-size: 18px;
    color: #333333;
    height: 48px;
}

.resultItem:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.resultItem:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.resultItem button {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    padding: 12px;
    width: 100%;
    height: 100%;
    text-align: left;
    font-size: 18px;
}

.resultItem:hover,
.selected {
    background: var(--ag-secondary);
}

.groupLabel {
    padding-block: 10px;
    margin: 0;
    color: var(--ag-deactivated-alpha);
}
.groupLabel {
    position: sticky;
    display: block;
    top: 0;
    background-color: var(--ag-background);
    border-radius: 8px;
    color: var(--ag-deactivated-alpha);
    padding: 10px;
}
.emptyResult {
    text-align: center;
    padding-block: 10px;
    margin: 0;
    color: var(--ag-deactivated-alpha);
}
