.container {
    display: grid;
    gap: 20px;
}
.infoRow {
    display: grid;
    column-gap: 20px;
    grid-template-columns: max-content 60px;
    grid-template-rows: 30px minmax(50px, auto);
    justify-content: space-between;
    width: inherit;
}
.infoRow p {
    font-size: 16px;
    color: #5e5e5e;
    font-weight: 600;
}
.infoRow h3 {
    margin-top: 16px;
    grid-column: 1/3;
    text-wrap: wrap;
    width: inherit;
}
.box {
    border: 1px solid #e6e8eb;
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
}
.accountInfoContainer {
    display: grid;
    gap: 40px;
    width: 280px;
}
.topSection {
    display: grid;
    grid-template-columns: max-content auto;
    gap: 20px;
}
.totalPayout {
    margin: auto;
}
.button {
    width: 120px;
    justify-self: end;
}

/* Chrome, Safari, Edge, Opera */
.numberInput input::-webkit-outer-spin-button,
.numberInput input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.numberInput input[type='number'] {
    -moz-appearance: textfield;
}
