/*******************************
         Site Overrides
*******************************/

.ui.modal > .header {
    text-align: center;
}

.ui.dimmer .ui.modal .ui.loader:before {
  border-color: rgba(0,0,0,.1);
}
          
.ui.dimmer .ui.modal .ui.loader:after {
  border-color: #fa824c transparent transparent;
}