.codeCard {
    width: 300px;
}

.bigSearch {
    margin: 10px 10px 10px 0px;
    width: 400px;
    height: 50px;
    font-size: 24px;
}

.paddingDiv {
    height: 500px;
}

.filters {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 30px;
    justify-content: space-between;
}

.secondaryButton {
    width: 180px;
    height: 36px;
}

.primaryButton {
    width: 180px;
    height: 36px;
}

.searchInput {
    width: 260px;
    height: 36px;
}
.addButtonContainer {
    width: 100%;
    justify-content: end;
    display: flex;
    margin-bottom: 20px;
}
