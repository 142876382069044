/*******************************
        User Overrides
*******************************/

.ui.segment {
    position: relative;
    box-shadow: 0px 0px 0px 0 rgb(0,0,0);
    margin: 1rem 0em;
    padding: 1em 1em;
    border-radius: 0.28571429rem;
    border: 0px solid rgba(0,0,0, 0.15);
    height: 100%;
}