.carousel {
    position: relative;
    width: inherit;
    height: inherit;
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
}
.loader {
    position: absolute;
    height: inherit;
    width: inherit;
    top: 0;
    left: 0;
}
.image {
    width: inherit;
    height: inherit;
    position: relative;
}
.image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.label {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
}
.arrow {
    position: absolute;
    top: 50%;
    z-index: 2;
}

.arrow svg {
    height: 24px;
    width: 24px;
    border-radius: 12px;
    padding: 4px;
    background-color: var(--ag-color-gray7);
}

.arrow.left {
    left: 10px;
}

.arrow.right {
    right: 10px;
}
