.ag-form-wrapper {
    display: grid;
    gap: 2rem;
    width: 100%;
}

.input-wrapper {
    margin: 0;
    padding: 0;
}

/* Input general styles */
.ag-form-wrapper input:not([type='checkbox']):not([type='radio']),
.ag-form-wrapper .div-as-input,
.ag-form-wrapper .button-as-input {
    box-sizing: border-box;
    line-height: 48px;
    /* If height value is change please also change width and height in `.ag-form-wrapper input[type='radio']`  */
    max-height: 48px;
    height: 100%;
    width: 100%;
    padding-block: 12px;
    background: var(--ag-background);
    color: var(--ag-color-gray1);
    font-family: Outfit, Helvetica Neue, Arial, Helvetica, sans-serif;
}
.ag-form-wrapper .ag-dropdown {
    padding-block: 12px;
}
.ag-form-wrapper input:not([type='checkbox']):not([type='radio']),
.ag-form-wrapper .div-as-input,
.ag-form-wrapper .button-as-input {
    font-size: 16px;
    border: 0.5px solid var(--ag-color-gray7);
    border-radius: 10px;
    outline: none;
}
.ag-form-wrapper input:not([type='checkbox']):not([type='radio']):hover {
    border: 0.5px solid var(--ag-color-gray1);
}
.ag-form-wrapper input::placeholder {
    color: var(--ag-deactivated-alpha);
}
.ag-form-wrapper input:not([type='checkbox']):not([type='radio']),
.ag-form-wrapper .div-as-input,
.ag-form-wrapper .button-as-input {
    padding-inline: 10px;
}
.ag-form-wrapper input[type='radio'] {
    padding-inline: 0;
    padding-block: 0;
    width: 20px;
    height: 20px;
}

/* Input styles when selected*/
.ag-form-wrapper input:not([type='checkbox']):not([type='radio']):focus,
.ag-form-wrapper .credit-card-input-focus,
.ag-form-wrapper .button-as-input.expanded,
.ag-form-wrapper .div-as-input.focus {
    border: 1px solid var(--ag-primary);
}
.ag-form-wrapper .radio-in-form.selected input[type='radio'] {
    border-color: var(--ag-primary);
}

/* Input styles when having an error*/
.ag-form-wrapper .error input:not([type='checkbox']):not([type='radio']):not([class='combinedInput']),
.ag-form-wrapper .error .button-as-input .ag-form-wrapper .div-as-input.error {
    border: 1px solid var(--ag-error) !important;
}
.ag-form-wrapper .error .radio-in-form input[type='radio'] .ag-form-wrapper .div-as-input.error {
    border-color: var(--ag-error);
}

/* Input and Label with svg icon styles when disabled*/
.ag-form-wrapper input:disabled,
.ag-form-wrapper input:disabled::placeholder,
.ag-form-wrapper .disabled label,
.ag-form-wrapper .disabled .icon,
.ag-form-wrapper .disabled span {
    color: var(--ag-color-disabled) !important;
}

.ag-form-wrapper .required-symbol {
    color: var(--ag-error) !important;
    font-size: 18px;
    font-weight: bold;
    margin: 0 4px;
}

.ag-form-wrapper .disabled .credit-card-input {
    background-color: var(--ag-color-disabled);
}

.ag-form-wrapper .default-input-label {
    display: none;
}
.ag-form-wrapper .button-as-input.expanded {
    border-radius: 20px;
    padding: 0;
}

/* Label */
.ag-form-wrapper .label-wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 12px;
}

.ag-form-wrapper .icon-wrapper {
    width: 24px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ag-form-wrapper .icon-wrapper svg {
    fill: var(--ag-color-gray3);
}

.ag-form-wrapper label {
    font-size: 16px;
    color: #333333;
}

/* Code Input styles */

.ag-form-wrapper .codeInputWrapper {
    height: inherit;
}

.ag-form-wrapper .codeInput {
    display: grid;
    gap: 0 4px;
    justify-items: center;
}

.ag-form-wrapper .codeInput input {
    max-height: unset !important;
    height: 3rem !important;
    aspect-ratio: 4/5 !important;
    text-align: center;
    padding: 20% !important;
    height: 100% !important;
}

/* Firefox */
.ag-form-wrapper .codeInput {
    appearance: textfield;
    -moz-appearance: textfield;
}

.button-check {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-auto-flow: column;
    justify-content: left;
    align-items: center;
    font-family: Outfit, Helvetica Neue, Arial, Helvetica, sans-serif;
}

.button-check > input {
    margin-right: 8px;
    accent-color: black;
    cursor: pointer;
}
