/*******************************
         Site Overrides
*******************************/

.ui.table thead th {
    color: rgb(158, 156, 187);
    padding: 0.92857143em 0.78571429em;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 9px;
}

.ui.basic.table thead {
    box-shadow: none;
    background: #ffffff00;
    border-radius: 5px;
}

.ui.selectable.table tbody tr:hover, .ui.table tbody tr td.selectable:hover {
    background: #f4f5fd !important;
    color: #1d2529f2 !important;
    border-radius: 10px !important;
}

.ui.table tbody + tbody tr:first-child td {
    border-top: 0px solid rgba(34, 36, 38, 0);
}