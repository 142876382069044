.selectorOptions li {
    cursor: pointer;
    min-height: 48px;
}

.selectorOptions li:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.selectorOptions li:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.selectorOptions button {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    padding: 12px;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: Outfit, Helvetica Neue, Arial, Helvetica, sans-serif;
}

.selectorOptions li:hover,
.selected {
    background: var(--ag-secondary);
}

.selectorOptions {
    overflow-y: auto;
    padding: 0;
    margin-block: 0;
}

.multiSelect button,
.multiSelect span {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    padding: 16px;
    align-items: center;
}

.multiSelect input {
    cursor: pointer;
    accent-color: black;
}
