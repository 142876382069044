.dateSelector {
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
    padding: 12px;
}

.calendarIcon {
    height: 15px;
    width: 13px;
    fill: #626671;
}
