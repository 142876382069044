.timelineWidget {
    width: 100%;
    margin: 1rem 0;
    max-width: 1200px;
    display: grid;
    place-items: center;
}

.timelineWrapper {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 860px;
}

.timelineFilters {
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    max-width: 860px;
    margin-bottom: 1rem;
}

.dailyReportWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 174px), 1fr));
    gap: 1rem;
}

.enforcementStats {
    display: grid;
    place-items: 'center';
    gap: 1rem;
    margin: 1rem 0;
}

.enforcementEvent {
    padding: 1.5rem 2rem;
    border-left: 4px solid #3c91e6;
    margin: 36px 0;
}

.maintenanceEvent {
    padding: 1.5rem 2rem;
    border-left: 4px solid #d1581f;
    margin: 36px 0;
}

.end {
    text-align: center;
    margin: 3rem 0;
}

.imageWrapper {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    border-radius: 10px;
}

.snapshotWrapper {
    margin-top: 1.5rem;
}

.landscapeRatio {
    aspect-ratio: 4/3;
}

.portraitRatio {
    aspect-ratio: 3/4;
}

.snapshotImg {
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 10px;
    object-fit: cover;
}

.clearFilterWrapper {
    width: 120px;
    height: 100%;
}
