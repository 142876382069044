.loader {
    display: inline-block;
    position: relative;
}
.loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 2px;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.primary div {
    border: solid var(--ag-color-gray2);
    border-color: var(--ag-color-gray2) transparent transparent transparent;
}
.secondary div {
    border: solid #fff;
    border-color: #fff transparent transparent transparent;
}
.loader div:nth-child(1) {
    animation-delay: -0.45s;
}
.loader div:nth-child(2) {
    animation-delay: -0.3s;
}
.loader div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* SIZE SETTINGS */
/* SMALL */
.loader.sm {
    width: 32px;
    height: 32px;
}
.loader.sm div {
    width: 28px;
    height: 28px;
    border-width: 4px;
}
/* MEDIUM */
.loader.md {
    width: 46px;
    height: 46px;
}
.loader.md div {
    width: 42px;
    height: 42px;
    border-width: 6px;
}
/* LARGE */
.loader.lg {
    width: 60px;
    height: 60px;
}
.loader.lg div {
    width: 56px;
    height: 56px;
    border-width: 8px;
}
