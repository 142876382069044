.inline {
    display: flex;
    flex-direction: row;
}

.smallLabel {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 4px;
}

.graphWrapper {
    margin: 3rem 0;
}

/* This min-width has to equal max-width for @media in .smallLabel */
@media (min-width: 1440px) {
    .smallLabelDivider {
        display: none;
    }
}

/* This max-width has to equal min-width for @media in .smallLabelDivider */
@media (max-width: 1439px) {
    .smallLabel {
        position: relative;
        top: -16px;
    }
}
