/*******************************
         Site Overrides
*******************************/

.ui.checkbox input:checked ~ .box:after, .ui.checkbox input:checked ~ label:after {
    opacity: 1;
    color: rgb(120 19 255)!important;
}

.ui.checkbox input:checked ~ .box:after, .ui.checkbox input:checked ~ label:active::before {
    opacity: 1;
    color: rgb(120 19 255);
}

.ui.checkbox input:checked ~ .box:before, .ui.checkbox input:checked ~ label:before {
    background: #ffe7fe;
    border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox .box:before, .ui.checkbox label:before {
    background: #ffe7fe;
    transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;
    border: 0px solid #D4D4D5;
}


.ui.checkbox .box:hover::before, .ui.checkbox label:hover::before {
    background: #ffd0fd;
    border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox .box:active::before, .ui.checkbox label:active::before {
    background: #ffd0fd;
    border-color: rgba(34, 36, 38, 0.35);
}
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ .box:before, .ui.checkbox input:not([type=radio]):indeterminate:focus ~ label:before, .ui.checkbox input:checked:focus ~ .box:before, .ui.checkbox input:checked:focus ~ label:before {
    background: #ffe7fe;
}

.ui.checkbox input:focus ~ .box:before, .ui.checkbox input:focus ~ label:before {
    background: #ffe7fe;
}