.paginationContainer {
    display: grid;
    grid-auto-flow: column;
    gap: 4px;
    padding-block: 20px;
}

.paginationContainer button {
    background-color: transparent;
    border: none;
    color: var(--ag-color-gray1);
    font-size: 16px;
    height: 28px;
    width: 32px;
    text-align: center;
    cursor: pointer;
    font-family: Outfit, Helvetica Neue, Arial, Helvetica, sans-serif;
}
.paginationContainer button:disabled {
    cursor: auto;
    color: var(--ag-color-disabled);
}

.paginationContainer button.notNumberButton {
    width: fit-content;
    padding-inline: 16px;
}

.paginationContainer button.selected {
    background-color: var(--ag-color-gray6);
    border-radius: 4px;
    color: var(--ag-color-gray1);
}

.paginationContainer .dots {
    text-indent: 0;
    display: grid;
    align-items: center;
}
