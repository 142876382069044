.sectionTitle {
    color: #717680;
    padding-bottom: 12px;
}

.commentSection {
    padding-bottom: 12px;
    color: #545a63;
}
.commentSection p {
    font-size: 16px;
}

.commentSection svg {
    margin-right: 4px;
}

.reasonList {
    padding-bottom: 12px;
}

.listNumber {
    font-size: 16px;
    font-weight: 400;
    margin-right: 24px;
    display: inline-block;
    width: 14px;
}
.reason {
    font-size: 20px;
    font-weight: 600;
}

.emptyStateMessage {
    width: fit-content;
    margin: auto;
    color: #717680;
    font-size: 16px;
    padding-block: 80px;
}

.emptyStateMessage p:first-of-type {
    font-weight: 600;
}
