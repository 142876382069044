.usernameField {
    display: flex;
    align-items: center;
    border-radius: 4px;
    gap: 4px;
}

.usernameInput {
    width: 100%;
}

.inputs {
    display: grid;
    gap: 24px;
}
