.searchInputWrapper {
    border: 0.5px solid var(--ag-color-gray7);
    border-radius: 6px;
    padding: 4px 12px;
    display: grid;
    grid-template-columns: 20px 212px;
    align-items: center;
    gap: 4px;
}

.searchInputWrapper svg path {
    fill: var(--ag-deactivated-alpha);
}

.searchInput {
    padding: 0 4px;
    margin: 0;
    font-family: Outfit, Helvetica Neue, Arial, Helvetica, sans-serif;
}
