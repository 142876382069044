.paragraph {
    font-size: 16px;
}

.closeButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
}

.closeIcon {
    font-size: 0.75rem;
    fill: var(--ag-color-gray3);
}

.closeButton:hover .closeIcon {
    fill: var(--ag-color-gray1);
}
