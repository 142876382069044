.agDropdown {
    border: 0.5px solid var(--ag-color-gray7);
    border-radius: 8px;
    padding: 4px 12px;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.agDropdown.disabled {
    cursor: not-allowed;
}

.agDropdown.enabled:hover {
    border-color: var(--ag-color-gray3);
}

.textAndIcon {
    display: inline-flex;
    align-items: center;
    width: 90%;
    height: 23px;
}

.textAndIcon span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
}

.textAndIcon svg {
    margin-right: 8px;
}

.arrow {
    transition-duration: 200ms;
    transform: rotate(0);
}

.arrow.open {
    transform: rotate(180deg);
}

.contentContainer {
    border: 0.5px solid var(--ag-color-gray7);
    border-radius: 8px;
    padding: 0;
    margin-top: 8px;
    background-color: #ffffff;
    padding-right: 1px;
    position: absolute;
    z-index: 10;
    width: 100%;
    outline: none;
    overflow-y: auto;
}

.contentContainer .open {
    display: block;
}

.agDropdownContainer {
    position: relative;
}
