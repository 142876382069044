.calendarLabel {
    font-size: 16px;
    line-height: 1;
    margin: 0 1px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
}

.addEventBtn {
    margin-block: 32px;
    max-width: 120px;
}
