@media (max-width: 991px) {
    .timezoneWrapperLarge {
        display: none;
    }
}

@media (min-width: 992px) {
    .timezoneWrapperSmall {
        display: none;
    }
}
