.skeleton {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: blink 2s infinite;
    height: 22px;
    width: 100%;
    border-radius: 10px;
    width: inherit;
    height: inherit;
}

@keyframes blink {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
