/*******************************
         Site Overrides
*******************************/

/* Green */

.ui.green.tiny.label {
    background-color: #e4f4be !important;
    color: #2fb916 !important;
}

.ui.green.labels .label, .ui.green.label {
    background-color: #c6ffb6 !important;
    border-color: #c6ffb6 !important;
    color: #03853b !important;
}

.ui.blue.labels .label, .ui.blue.label {
    background-color: #e3eeff !important;
    border-color: #3c91e6 !important;
    color: #455fff !important;
}

.ui.pink.labels .label, .ui.pink.label {
    background-color: #ffcaff !important;
    border-color: #eb25eb !important;
    color: #eb25eb !important;
}