:root {
    --ag-primary: #ff6712;
    --ag-secondary: #fff8ea;
    --ag-secondary-alpha: #fff8ea99;
    --ag-error: #e81919;
    --ag-deactivated-alpha: #33333380;
    --ag-black1: #1c1c1c;
    --ag-color-gray1: #333333;
    --ag-color-gray2: #a1a6af;
    --ag-color-gray3: #717680;
    --ag-color-gray4: #d3d6db;
    --ag-color-gray5: #f9f9fc;
    --ag-color-gray6: #e4e6ee;
    --ag-color-gray7: #d0d0d0;
    --ag-color-gray8: #f1ece8;
    --ag-color-gray9: #838383;
    --ag-background: #ffffff;
    --ag-color-disabled: #cccccc;
    --ag-color-link: #4183c4;
}
