.title {
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.message {
    white-space: pre-line;
}
