.dashboardHeader {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboardLogo {
    height: 3rem;
}

.detailSectionWrapper {
    margin: 2rem 0;
}

.detailSectionAddress {
    margin: 1rem 0;
    font-size: 3rem;
    font-weight: 500;
}

.detailSectionTitle {
    margin: 1rem 0;
    font-size: 1.5rem;
    font-weight: 400;
}

.tableWrapper {
    width: 100%;
    padding-bottom: 4rem;
}

.logoutWrapper {
    width: 90px;
}

.baseButton {
    border: 0.5px solid var(--ag-deactivated-alpha);
    font-weight: 400;
    color: var(--ag-gray1);
    font-size: 14px;
    border-radius: 8px;
}
