.rightSpace {
    margin-right: 1em;
}

.smallWidth {
    max-width: 8em;
}

.spaceOut {
    display: flex;
    justify-content: space-between;
}

.addCode {
    padding-bottom: 1em;
}

.buttonGroup {
    display: grid;
    gap: 8px;
    grid-auto-flow: column;
}

.newCodeInputs {
    display: grid;
    justify-content: space-between;
    grid-auto-flow: column;
}

.timeSelector {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
}
