@media (min-width: 1700px) {
    .spaceBetween {
        padding: 0 !important;
        height: 115%;
        /* We need to cheat the legend upward in to the widget box's title */
        margin-top: -2rem !important;
    }
}

.spaceBetween {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.legendList {
    padding: 0;
    margin: 0;
    list-style: none;
}

.legendListItem {
    margin: 0.75rem 0;
}

.legendListItem:before {
    content: '\2022';
    font-size: 3rem;
    margin: auto 0.5rem;
    vertical-align: bottom;
}

.legendListItem.baseline:before {
    color: #e4e6ee;
}

.legendListItem.dp:before {
    color: #efab8a;
}

.legendListItem.enforcement:before {
    color: #d1581f;
}

.legendListItem.marketing:before {
    color: #9ea2ff;
}

.upliftWrapper {
    border: 1px solid #e6e8eb;
    border-radius: 1rem;
    padding: 1.2rem;
}

.upliftTitle {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 1.2rem;
    margin-left: 0.25rem;
}

.upliftLabel {
    display: block;
    margin-top: 1rem;
}

.upliftPercent {
    font-size: 1.25rem;
    margin-right: 1rem;
}
