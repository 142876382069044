.searchSelectWrapper {
    position: relative;
}

.arrowsButton {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 48px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}
