.pencilIconButton {
    border: none;
    background: inherit;
    cursor: pointer;
}

.searchInput {
    margin-bottom: 10px;
    height: 40px;
}

.card {
    max-width: 350px;
}

.editIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
