/*******************************
         Site Overrides
*******************************/

.ui.menu .header.item, .ui.vertical.menu .header.item {
    opacity: .4;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-size: 12px;
}

.ui.menu {
    min-height: auto; 
}

.ui.menu .ui.dropdown .menu > .selected.item {
    background: rgb(234 239 255) !important;
    font-weight: bold !important;
    color: rgb(10 88 255) !important;
}

.ui.pagination.secondary.menu .item{
    justify-content:center;
}