.toggleWrapper {
    text-align: start;
    margin: 12px 0;
    height: 22px;
    display: inline-block;
}

.toggleWrapper label {
    line-height: 30px;
    vertical-align: middle;
    margin-left: 4px;
}
.toggleWrapper input[type='checkbox'] {
    vertical-align: middle;
    appearance: none;
    padding: 0;
    margin: 0;
    width: 38px;
    height: 22px;
    border: 1px solid var(--ag-color-gray3);
    background: var(--ag-background);
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.toggleWrapper input[type='checkbox']:checked {
    background: var(--ag-primary);
    border: 1px solid var(--ag-primary);
}

.toggleWrapper input[type='checkbox']:after {
    position: absolute;
    content: '';
    width: 20px;
    height: 18px;
    border: none;
    border-radius: 100px;
    background: var(--ag-color-gray3);
    -webkit-box-shadow: 0 0 0.25em rgba(var(--ag-background), 0.3);
    box-shadow: 0 0 0.25em rgba(var(--ag-background), 0.3);
    /* transform: scale(0.8); */
    left: 2px;
    top: 1px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.toggleWrapper input[type='checkbox']:checked:after {
    left: calc(100% - 22px);
    background: var(--ag-background);
    border: 1px solid var(--ag-primary);
}
