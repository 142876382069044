/*******************************
        Site Overrides
*******************************/

.ui.red.message {
    background-color: #ffedf9;
    color: #ff1982;
    box-shadow: 0px 0px 0px 1px #ff1982 inset, 0px 0px 0px 0px #fff;
}

.ui.red.message .header {
    color: #ff1678;
}

.ui.green.message {
    background-color: #ebffe7 !important;
    color: #0d715a !important;
    box-shadow: 0px 0px 0px 1px #0d7167 inset, 0px 0px 0px 0px #fff;
}