.container {
    min-height: 80vh;
}

.row {
    display: flex;
}

.grid {
    height: fit-content;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 1rem;
}

.item {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
}

.itemTitle {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 1rem;
}

.videoContainer {
    background-color: #eaeaea;
    height: 240px;
    width: 100%;
    border-radius: 12px;
}

.video {
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.cardContainer {
    background-color: rgba(233, 233, 233, 0.8);
    border-radius: 10px;
    height: fit-content;
    width: 100%;
    margin: 4rem 0;
    padding: 3rem 2rem;
}

.cardContainer:first-child {
    margin-right: 2rem;
}

.cardTitle {
    font-size: 1.9rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
}

.cardDescription {
    font-size: 1.5rem;
    font-weight: medium;
    color: rgba(0, 0, 0, 0.74);
    margin: 2rem 0 !important;
}

.email {
    font-size: 1.3rem;
    font-weight: bold;
    color: rgb(100, 153, 255);
    margin-bottom: 2rem;
    display: block;
}

.phone {
    font-size: 1.3rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 2rem;
    display: block;
}

.cardFooter {
    font-size: 1.3rem;
    font-weight: medium;
    color: rgba(0, 0, 0, 0.48);
}
