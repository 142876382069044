.dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin: 0 10px 0 0;
    display: inline-block;
    vertical-align: middle;
}

.noData {
    padding: 80px;
    border: solid 1px #e3e6ee;
    margin: 1rem;
    width: 100%;
}

.noDataContent {
    margin: auto;
    max-width: 440px;
}

.noDataContent p:first-of-type {
    font-weight: 600;
}

.noDataContent p {
    padding-block: 8px;
}

.noDataHeader {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    max-width: 300px;
    margin: auto;
}

.noDataHeader p {
    padding-block: 20px;
}
