.plate:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.plate {
    background: #ffeecc;
    border: 5px solid white;
    box-shadow: 0 2px 10px #999;
    float: left;
    display: inline;
    padding: 5px;
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 50px;
    height: 150px;
    width: 40%;
    min-width: 320px;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
}

.plate .box {
    border: 2px solid #fa824c;
    border-radius: 5px;
    height: 100%;
}

.plate h1 {
    color: #0f1349;
    font-family: 'Roboto Mono', monospace;
    text-shadow: 0 2px 1px #c36309, 0 -2px 1px rgb(255, 226, 130);
    letter-spacing: 3px;
    font-size: 60px;
    font-weight: 600;
    margin: 10px;
    text-align: center;
}

.plate p {
    color: white;
    background-color: #fa824c;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
}
