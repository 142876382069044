.rentalCount {
    margin-top: 12px;
    margin-bottom: 20px;
    display: grid;
    grid-auto-flow: column;
    gap: 10%;
}

.rentalCount div {
    border-right: 0.5px solid var(--ag-color-gray4);
}

.rentalCount div:last-of-type {
    border-right: none;
}

.rentalCount div > p {
    font-size: 12px;
}
