.dateWrapper {
    color: #292929;
    display: grid;
    grid-template-rows: 2rem 4rem;
    row-gap: 16px;
    text-align: center;
    margin-top: 2.5rem;
}

.dayWrapper {
    display: grid;
    grid-template-columns: 3rem minmax(auto, 778px);
    column-gap: 3rem;
}

.dayWrapper:first-of-type .dateWrapper {
    color: #d1581f;
}

.dayName {
    display: block;
    font-weight: 500;
    font-size: 2rem;
}

.dateNum {
    display: block;
    font-weight: 600;
    font-size: 4rem;
}

.entryWrapper {
    list-style: none;
    padding: 0;
    margin: 0;
}

.collapsibleWrapper {
    display: grid;
    width: 100%;
    place-items: center;
    grid-template-columns: 64px 1fr;
    gap: 2rem;
    overflow: hidden;
}

.expandedContent {
    overflow: hidden;
}

.collapseButton {
    background: var(--ag-black1);
    padding: 0;
    width: 64px;
    height: 64px;
    border: none;
    border-radius: 50%;
    box-shadow: 0px 0px 19.27px 0px #0000000f;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.line {
    width: 96%;
    padding: 0 2%;
    height: 1px;
    background: #e6e8eb;
}

.collapsedCardWrapper {
    min-height: 200px;
    width: 100%;
    position: relative;
    margin: 2rem 0;
}

.collapsedCard {
    width: 100%;
    height: 100%;
    border: 1px solid #e6e8eb;
    background: #fff;
    box-shadow: 0px 4px 7.2px 0px #00000012;
    border-radius: 10px;
    position: absolute;
    padding: 2rem;
    overflow: hidden;
}

.collapsedCard1 {
    top: -1.5rem;
    transform: scale(1);
    z-index: 3;
}

.collapsedCard2 {
    top: 0rem;
    transform: scale(0.95);
    z-index: 2;
}

.collapsedCard3 {
    top: 1.5rem;
    transform: scale(0.9);
    z-index: 1;
}

.rangeTitle {
    font-size: 20px;
}

.alertWrapper {
    margin: 2rem 0;
}

.listItem {
    list-style: none;
}
