.visibilityPage .visibility-section {
    border: 0.5px solid var(--ag-color-gray7);
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 20px;
}

.superuserToggle {
    position: absolute;
    top: 30px;
    right: 20px;
    display: flex;
    align-items: center;
}

.superuserView-off {
    display: none;
}
