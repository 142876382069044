.container {
    display: grid;
    grid-template-columns: auto 180px;
    gap: 20px;
}
@media (max-width: 767px) {
    .container {
        grid-template-columns: auto;
    }
}

.itemsContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}
.item:first-of-type {
    margin-bottom: 24px;
}
.item p {
    font-size: 32px;
    margin-bottom: 0 !important;
}
.item span {
    color: var(--ag-color-gray3);
    font-size: 16px;
}
.item svg {
    height: 20px;
    width: 20px;
    margin-right: 12px;
}
.item svg path {
    fill: black;
}

.barsAndMaxContainer {
    display: grid;
    grid-template-columns: auto max-content;
}
.barsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
.bar {
    height: 56px;
    border-radius: 8px;
    background-color: var(--ag-color-gray8);
    border: 1px solid transparent;
    margin-block: 68px;
}
.bar.filled {
    background-color: #059669;
}
.bar.peak {
    border-color: #7dcd17;
}
.barLabel {
    position: absolute;
    text-wrap: nowrap;
}
.barLabel span:first-of-type {
    font-size: 16px;
    font-weight: bold;
    margin-left: -4px;
}
.barLabel span:last-of-type {
    color: var(--ag-color-gray9);
    margin-left: -4px;
}
.barLabel .line {
    height: 16px;
    width: 1px;
    background-color: var(--ag-color-gray9);
    margin-block: 4px;
}

.maxCapacity {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    color: #9f9c9a;
}
.maxCapacity span:first-of-type {
    font-size: 16px;
    font-weight: bold;
}
.maxCapacity span:last-of-type {
    font-size: 12px;
}
