.emptyStateMessage {
    width: fit-content;
    margin: auto;
    color: #717680;
    font-size: 16px;
    padding-block: 80px;
}

.emptyStateMessage p:first-of-type {
    font-weight: 600;
}

.heatmap table {
    table-layout: fixed;
    width: 100%;
}
.heatmap .label {
    color: #717680;
    font-size: 12px;
}
.heatmap td {
    height: 16px;
    text-align: center;
    cursor: default;
}
.heatmap td + td {
    width: auto;
}
.heatmap td span {
    display: none;
    font-size: 10px;
}
.heatmap .values:hover span,
.heatmap .maxValue span {
    display: block;
}
.heatmap .maxValue svg {
    margin-right: 2px;
}
.detail {
    padding-block: 8px;
    border-radius: 20px;
    width: fit-content;
    margin: 4px;
    margin-bottom: 24px !important;
}
