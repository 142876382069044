.overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.3);
    height: 100vh;
    width: 100vw;
    margin: auto;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.wrapper {
    max-height: 90%;
    width: 100%;
    max-width: fit-content;
    background-color: #fff;
    border-radius: 12px;
    z-index: 2;
}
.wrapper.standard {
    padding: 2rem 1.5rem;
    overflow-y: scroll;
    display: grid;
    gap: 2rem;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wrapper.standard::-webkit-scrollbar {
    display: none;
}

.wrapper.fullSizeImage {
    overflow-y: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.wrapper.fullSizeImage .header {
    height: 0;
}

.headerRightSide {
    display: flex;
    align-items: center;
}

.title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
}

.closeButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrapper.fullSizeImage .closeButton {
    position: absolute;
    fill: var(--ag-color-gray8);
    top: 20px;
    right: 20px;
}

.closeIcon {
    font-size: 0.75rem;
    fill: var(--ag-color-gray3);
}

.wrapper.fullSizeImage .closeIcon {
    fill: #fff;
}

.closeButton:hover .closeIcon {
    fill: var(--ag-color-gray1);
}

.wrapper.fullSizeImage .closeButton:hover .closeIcon {
    fill: var(--ag-color-gray3);
}

.iconWrapper {
    width: 24px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconWrapper svg {
    fill: var(--ag-color-gray3);
}

.content {
    display: flex;
    flex-direction: column;
}
