.filters {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-block: 40px;
    min-height: 40px;
}

.rentalType {
    text-transform: capitalize;
}
