.tableContainer {
    width: 100%;
    margin-bottom: 28px;
}
.revenueUpliftContainer {
    background-color: #f8f9fa;
    border: 0.5px solid var(--ag-color-gray7);
    border-radius: 6px;
    padding: 20px;
}
.revenueTitle,
.upliftInfo p {
    font-size: 16px;
    font-weight: 700;
}
.revenueDetail {
    padding-block: 12px;
}
.revenueTitle {
    display: inline-flex;
    align-items: center;
}
.revenueTitle svg {
    height: 16px;
    width: 16px;
    margin-right: 8px;
}
.upliftInfoContainer {
    display: grid;
    grid-gap: 24px 12px;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}
.upliftInfo {
    min-width: 50px;
}
.upliftInfoTitle {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
}
.upliftInfoTitle > div {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    margin-right: 8px;
}
.upliftInfoTitle ~ p {
    font-size: 2rem;
    font-weight: normal;
}
.dateSelector {
    width: 100%;
    display: grid;
    justify-items: center;
    padding: 12px;
}
.dateSelectorButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px 4px;
    margin-block: 12px;
}
.dateSelectorButtons button {
    background-color: #ffff;
    border: 0.5px solid var(--ag-color-gray7);
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 8px;
    font-family: Outfit, Helvetica Neue, Arial, Helvetica, sans-serif;
    cursor: pointer;
}
.dateSelectorButtons button:hover {
    background-color: #e2e2e2;
}
.headerOptions {
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
    padding-inline: 14px;
    margin-top: 20px;
}
.downloadModalContainer {
    display: grid;
    gap: 16px;
}
.downloadButton {
    width: 140px;
}
.modalDownloadButton {
    margin-top: 16px;
}
.calendarIcon {
    height: 15px;
    width: 13px;
    fill: #626671;
}
