.container {
    height: 100dvh;
    display: grid;
    grid-template-columns: 50% 50%;
}

.loginForm > div {
    width: 400px;
    margin: auto;
    display: grid;
    gap: 24px;
}

.loginForm {
    padding: 28px;
    margin-inline: auto;
    display: grid;
    grid-template-rows: 20% 40%;
}

.backgroundImage {
    object-fit: cover;
}

@media (max-width: 900px) {
    .backgroundImage {
        display: none;
    }
    .container {
        grid-template-columns: 100%;
    }
}
