.flyerWrapper {
    height: 600px;
    aspect-ratio: 0.7;
    overflow: hidden;
}

.flyerHeader {
    background-color: #d1581f;
    color: white;
    height: 35%;
    display: grid;
    gap: 1rem;
    place-items: center;
    padding: 2rem 3rem;
    position: relative;
}

.flyerDownloadButton {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: white;
    padding: 6px;
    background: transparent;
    border: 1.5px solid #fff;
    border-radius: 4px;
    aspect-ratio: 1;
    width: 30px;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.flyerDownloadButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.flyerDownloadIcon {
    margin: 0 !important;
}

.flyerAGLogo {
    width: 120px;
}

.flyerTitle {
    font-size: 30px;
    color: white;
    text-align: center;
    margin: 0;
}

.flyerSubtitle {
    color: var(--ag-black1);
    font-size: 26px;
    font-weight: bold;
    text-align: center;

    appearance: none;
    background-color: transparent;
    border: none;
    outline: none;
    resize: none;
    font-family: inherit;
    padding: 2px;
    margin: 0;
}

.loaderContainer {
    width: 100%;
    height: 100%;
}

.flyerMain {
    height: 65%;
    display: grid;
    place-items: center;
    padding: 2rem 3rem;
}

.flyerQrCode {
    width: 60%;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    margin: 1rem 0;
}

.flyerQrCodeImg {
    width: 100%;
    aspect-ratio: 1;
}

.flyerLabel {
    font-size: 12px;
    text-align: center;
    margin: 0;
    color: var(--ag-color-gray2);
}

.flyerValue {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin: 0;
}
