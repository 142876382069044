.monthTable th {
    font-weight: 400;
    font-size: 12px;
    padding: 4px;
    border-bottom: 1px solid #f1f1f1;
}
.monthTable {
    width: fit-content;
    border-spacing: 0;
}
td {
    padding: 0;
}
.day {
    border: none;
    background: none;
    font-size: 12px;
    width: 36px;
    height: 36px;
    color: var(--ag-color-gray1);
    font-family: 'Outfit';
}
.today {
    padding: 9px;
    border: 3px solid var(--ag-color-gray4);
    border-radius: 100%;
}
.disabled {
    color: var(--ag-color-disabled);
}
.selected {
    padding: 9px;
    border: 3px solid var(--ag-primary) !important;
    border-radius: 100%;
    background-color: var(--ag-primary);
    color: var(--ag-background);
}
.blocked {
    text-decoration-line: line-through;
}
.startOfRange,
.inRange:first-child {
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
}
.endOfRange,
.inRange:last-child {
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
}
td:has(button).inRange {
    background: rgba(250, 130, 76, 0.2);
}
td:has(button).inRange button {
    text-decoration-line: none !important;
}
.firstOfMonth.inRange {
    background: linear-gradient(to right, rgba(250, 130, 76, 0), rgba(250, 130, 76, 0.2)) !important;
}
.lastOfMonth.inRange {
    background: linear-gradient(to right, rgba(250, 130, 76, 0.2), rgba(250, 130, 76, 0)) !important;
}
.week {
    width: 100%;
    height: 1rem;
}
.monthYear {
    font-weight: 500;
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.monthYear p {
    margin: 0 !important;
}
.monthYear button {
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    padding: 4px;
}
.monthYear button:first-of-type {
    transform: rotate(180deg);
}
.pickerWrapper {
    width: fit-content;
}
