.tabWrapper {
    width: 100%;
}
.tabMenu {
    display: grid;
    grid-auto-flow: column;
    gap: 40px;
    width: fit-content;
    margin-bottom: -2px;
}
.tabDivider {
    border-bottom: var(--ag-color-gray7) 2px solid;
    margin-bottom: 12px;
}
.tabMenu button {
    background-color: transparent;
    border: none;
    font-family: Outfit, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-size: 20px;
    padding-block: 12px;
    padding-inline: 0;
    color: #3e3d43;
    cursor: pointer;
}

.active {
    font-weight: bold;
    border-bottom: var(--ag-primary) solid 2px !important;
}
