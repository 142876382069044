.barsContainer {
    display: grid;
    grid-template-columns: 75% 25%;
}

.legendContainer {
    margin: auto;
}

.legend {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-block: 4px;
}

.legendCircle {
    width: 8px;
    height: 8px;
    border-radius: 4px;
}

.divider {
    width: 80%;
    border-bottom: 1px solid #dcdce5;
    margin-block: 32px;
}
.subtitle {
    color: #717680;
    font-size: 18px;
}

.graphContainer {
    max-width: 120px;
    text-align: center;
}

.graphContainer p {
    font-size: 12px;
    color: #717680;
    padding-top: 8px;
}

.detail {
    padding-block: 8px;
    border-radius: 20px;
    width: fit-content;
    margin: 4px;
    margin-bottom: 24px !important;
}
