.badge {
    background-color: var(--ag-primary);
    color: white;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 10px;
    font-weight: bold;
    display: inline-block;
    margin: 0 8px;
}
