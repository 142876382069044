.balanceWrapper {
    margin-bottom: 2rem;
}

.balance {
    display: flex;
    gap: 0.5rem;
    color: var(--ag-color-gray2);
    align-items: flex-end;
    font-size: 16px;
}

.balanceAmount {
    font-size: 36px;
    line-height: 36px;
    font-weight: 600;
    display: block;
    color: var(--ag-color-gray1);
}

.warningBoxWrapper {
    margin-bottom: 2rem;
}

.zeroBalance {
    color: #059669;
}

.greenButton {
    background-color: #059669;
    color: #fff;
}
