.promoButton {
    width: 250px !important;
}

.promoCodeReportContainer {
    border: 1px solid rgba(34, 36, 38, 0.15);
    padding: 2em;
    border-radius: 0.28571429rem;
}

.promoFilterContent {
    font-size: 1em;
    width: 250px;
    padding: 15px;
}
