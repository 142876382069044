.contentWrapper {
    margin: 4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.message {
    margin: 1rem 0;
    font-size: 24px;
    font-style: normal;
    text-align: center;
}
