/*******************************
         Site Overrides
*******************************/

.ui .button {
    letter-spacing: 1.4px;
}


/* Green */

.ui.green.button {
    background-color: #e4f4be;
    color: #2fb916;
}

.ui.green.button:hover {
    background-color: #e2ff9c;
    color: #2fb916;
}

/* Blue */

.ui.blue.button {
    background-color: #d8ebff;
    color: #3c91e6;
}

.ui.blue.button:hover {
    background-color: #d8ebff;
    color: #3c91e6;
}

/* Red */

.ui.red.button {
    background-color: rgba(0,0,0,0);
    color: #e81919;
}

.ui.red.button:hover {
    background-color: #ffdae1;
    color: #e81919;
}