.inputWrapper {
    position: relative;
    cursor: pointer;
}

.input {
    cursor: pointer;
    height: 100%;
    width: 100%;
    border: 1px solid var(--ag-deactivated-alpha);
}

.clickableArrows {
    cursor: pointer;
    all: unset;
    position: absolute;
    top: 0;
    right: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}
